<template>
  <div>
    <header-folder-buttons
      v-if="showHeaderFolderButtons"
      :user-id="userObject.userId"
      :group-id="groupId"
      :is-sys-admin="userObject.isSysAdmin"
    />
    <header-action-project-buttons
      v-if="showHeaderActionProjectButtons && privilegesForItem"
      :is-sys-admin="userObject.isSysAdmin"
      :privileges-for-item="privilegesForItem"
    />
    <header-action-page-buttons
      v-if="showHeaderActionPageButtons && privilegesForItem"
      :is-sys-admin="userObject.isSysAdmin"
      :privileges-for-item="privilegesForItem"
    />
    <header-action-save-buttons v-if="showHeaderActionSaveButtons"/>
    <header-action-back-button
      v-if="showHeaderBackButtons"
      :user-object="userObject"
      :group-id="groupId"
      :pageType="currentPageType"
    />
  </div>
</template>

<script>
export default {
  name: 'HeaderActionButtons',
  components: {
    HeaderActionPageButtons: () => import('@/components/header/Buttons/HeaderActionPageButtons.vue'),
    HeaderActionBackButton: () => import('@/components/header/Buttons/HeaderActionBackButton.vue'),
    HeaderActionProjectButtons: () => import('@/components/header/Buttons/HeaderActionProjectButtons.vue'),
    HeaderActionSaveButtons: () => import('@/components/header/Buttons/HeaderActionSaveButtons.vue'),
    HeaderFolderButtons: () => import('@/components/header/Buttons/HeaderFolderButtons.vue'),
  },
  computed: {
    showHeaderActionSaveButtons() {
      return this.currentPageType === 'folder-settings'
        || this.currentPageType === 'project-settings'
        || this.currentPageType === 'general-settings'
        || this.currentPageType === 'page-settings';
    },
    showHeaderActionProjectButtons() {
      return this.currentPageType === 'project';
    },
    showHeaderActionPageButtons() {
      return this.currentPageType === 'page';
    },
    showHeaderFolderButtons() {
      return this.currentPageType === 'folder';
    },
    showHeaderBackButtons() {
      return !!this.$route.params.parametersOfUrl
        || this.currentPageType === 'general-settings'
        || this.$route.path === '/folder-settings';
    },
  },
  props: {
    userObject: {
      type: Object,
    },
    groupId: {
      type: Number,
    },
    privilegesForItem: {
      type: Array,
    },
    currentPageType: {
      type: String,
    },
  },
};
</script>
