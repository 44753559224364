var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.showHeaderFolderButtons
        ? _c("header-folder-buttons", {
            attrs: {
              "user-id": _vm.userObject.userId,
              "group-id": _vm.groupId,
              "is-sys-admin": _vm.userObject.isSysAdmin
            }
          })
        : _vm._e(),
      _vm.showHeaderActionProjectButtons && _vm.privilegesForItem
        ? _c("header-action-project-buttons", {
            attrs: {
              "is-sys-admin": _vm.userObject.isSysAdmin,
              "privileges-for-item": _vm.privilegesForItem
            }
          })
        : _vm._e(),
      _vm.showHeaderActionPageButtons && _vm.privilegesForItem
        ? _c("header-action-page-buttons", {
            attrs: {
              "is-sys-admin": _vm.userObject.isSysAdmin,
              "privileges-for-item": _vm.privilegesForItem
            }
          })
        : _vm._e(),
      _vm.showHeaderActionSaveButtons
        ? _c("header-action-save-buttons")
        : _vm._e(),
      _vm.showHeaderBackButtons
        ? _c("header-action-back-button", {
            attrs: {
              "user-object": _vm.userObject,
              "group-id": _vm.groupId,
              pageType: _vm.currentPageType
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }